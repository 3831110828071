<template>
    <div class="div1">
        <div class="div1_general">
            <div class="div1_left will-fadeIn">
                <h1 class="div1_left_1">{{ page_strategie().bloc1.title}}</h1>
                <div class="div1_left_2">{{ page_strategie().bloc1.desc1}}</div>
                <div class="div1_left_3"><ui><li v-for="(phrase, indexPhrase) in page_strategie().bloc1.phrases" :key="indexPhrase">{{ phrase }}</li></ui></div>
            </div>
            <div class="div1_right will-fadeIn fadeFromRight">
                <div class="div1_right_general">
                    <div class="div1_right_1">{{ page_strategie().bloc1.form_title}}</div>
                    <div class="messageConfirmation" :class="[(!messageConfirmation) ? 'invisible' : '', erreur ? 'error' : 'succes']">{{ messageConfirmation }}</div>
                    <div class="div1_right_2">
                        <input v-model="nom" type="text" class="inputSR" :placeholder="'*' + page_strategie().bloc1.placeholder1" name="nom" autocomplete="autocomplete_off_hack_xfr4!k"/>
                        <input v-model="prenom" type="text" class="inputSR" :placeholder="'*' + page_strategie().bloc1.placeholder2" name="prenom" autocomplete="autocomplete_off_hack_xfr4!k"/>
                        <input v-model="url" type="text" class="inputSR" :placeholder="'*' + page_strategie().bloc1.placeholder3" name="url_site" autocomplete="anyrandomstring"/>
                        <input v-model="tel" type="text" class="inputSR" :placeholder="'*' + page_strategie().bloc1.placeholder4" name="tel" autocomplete="autocomplete_off_hack_xfr4!k"/>
                    </div>
                    <div class="div1_right_3">
                        <input class="pointer" type="checkbox" name="politique_conf">
                        <span class="div1_right_3_2">{{ page_strategie().bloc1.checkbox }}</span>
                    </div>
                    <div class="div1_right_4">
                        <div class="boutton_offre relative" @click="sendFormulaire">
                            <p class="texte_boutton_offre">{{ page_strategie().bloc1.bouton }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h2 class="div2 will-fadeIn">{{ page_strategie().bloc2.title }}</h2>
    <div class="div3 will-fadeIn fadeFromRight">{{ page_strategie().bloc2.desc }}</div>
    <div class="div4">
        <!--<div class="div4_1"></div>-->
        <img class="strategie_img1 will-fadeIn" src="../assets/strategie_img1.png">
        <div class="div4_2"></div>
        <div class="div4_3">
            <p class="will-fadeIn fadeFromRight" v-for="(phrase, indexPhrase) in page_strategie().bloc2.phrases" :key="indexPhrase">{{ phrase }}</p>
        </div>
    </div><!--
    <div class="div5">
        <img class="div5_1" src="../assets/landing_phone.png">
    </div>-->
    <div class="div6">
        <div class="div6_general">
            <h2 class="div6_1 will-fadeIn">{{ page_strategie().bloc3.title }}</h2>
            <div class="div6_2">
                <p class="will-fadeIn" v-for="(phrase, indexPhrase) in page_strategie().bloc3.phrases" :key="indexPhrase">{{ phrase }}</p>
            </div>
        </div>
        <img class="div6_3" src="../assets/landing_phone2.png">
    </div>
    <div class="div7">
        <div></div>
        <div>
            <h2 class="div7_1 will-fadeIn fadeFromRight">{{ page_strategie().bloc4.title }}</h2>
            <div class="div7_2">
                <div class="card will-fadeIn fadeFromRight" v-for="(card, indexCard) in page_strategie().bloc4.cards" :key="indexCard">
                    <div class="card_general">
                        <div class="icon_container">
                            <div :class="page_global().bloc4.favicon[indexCard]" class="icon"></div>
                        </div>
                        <div class="card_title">{{ card.title }}</div>
                        <div class="card_text">{{ card.desc }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="div8 will-fadeIn">
        <div class="div8_general">
            <h2 class="div8_1">{{ page_strategie().bloc5.title }}</h2>
            <div class="div8_2">{{ page_strategie().bloc5.desc }}</div>
            <div class="boutton_offre div8_3">
                <p class="texte_boutton_offre">{{ page_strategie().bloc5.bouton }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import json_config from "@/json/config.json";
import page_glb from "@/json/page_global.json";
import page_strategie from "@/json/page_strategie.json";

export default {
    name: 'StrategieRGPD',
    data(){
        return{
            page_glb: page_glb,
            page_strategie: page_strategie,
            nom:"",
            prenom:"",
            url:"",
            tel:"",
            messageConfirmation: "",
            erreur: false
        }
    },
    methods:{
        page_strategie(){
            return this.page_strategie[this.$store.state.codeLangue]
        },
        page_global(){
            return this.page_glb.global.strategie_rgpd
        },
        sendFormulaire(){
            if(this.nom && this.prenom && this.url && this.tel){
                this.erreur=false
                this.messageConfirmation = this.page_strategie().bloc1.valid
                var mesDonnees = new FormData();
                mesDonnees.set("nom", this.nom);
                mesDonnees.set("prenom", this.prenom);
                mesDonnees.set("url", this.url);
                mesDonnees.set("tel", this.tel);
                axios({
                    method: 'post',
                    url: json_config.phpFiles_url + '/sendStrategieForm.php',
                    data: mesDonnees
                })
                .then((result) => {
                    console.log(result)
                })
            }
            else{
                this.erreur=true
                this.messageConfirmation = this.page_strategie().bloc1.erreur
            }
        }
    },
    mounted(){
        this.$store.state.cliquable=true
    } 
}
</script>

<style scoped>

.relative{
    position: relative;
}

.messageConfirmation{
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
    min-height: 34px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.succes{
    color: #04D98B;
}

.error{
    color: #F2505D;
}

.invisible{
    visibility: hidden
}

.div1{
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    background: linear-gradient(rgba(2, 40, 115, 0.85), rgba(2, 40, 115, 0.85)), url(../assets/landing_back.png) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.div1_general{
    padding-top: 200px;
    display: flex;
    justify-content: space-around;
}

.div1_left{ 
    color: #FFFFFF;
    text-align:left;
}

.div1_left_1{
    margin-top: 32px;
    width: 376px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
}

.div1_left_2{
    margin-top: 6px;
    width: 414px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.div1_left_3{
    margin-top: 20px;
    width: 550px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.div1_right{
    width: 381px;
    background: #F2F3FB;
    border-radius: 20px;
    height: fit-content;
}

.div1_right_general{
    padding-left: 56px;
    padding-right: 56px;
    padding-top: 45px;
}

.div1_right_1{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-right: auto;
    margin-left: auto;
    width: 222px;
}

.div1_right_2{
    margin-bottom: 28px;
}

input[type=text].inputSR{
    margin-bottom: 9px;
    background: transparent;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    padding-top: 19px;
    padding-left: 8px;
    padding-bottom: 8px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

input::placeholder{
    color: #CCCBCB;
}

input:focus-visible{
    outline: #42b983 auto 1px;
}

.div1_right_3{
    display: table;
    margin-bottom: 32px;
}

.div1_right_3_1{
    border: 1px solid #000000;
    box-sizing: border-box;
    width: 10px;
    height: 10px;
}

.div1_right_3_2{
    display: table-cell;
    padding-left: 9px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.div1_right_4{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.div2{
    margin-top: 153px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
}

.div3{
    margin-top: 16px;
    width: 559px;
    margin-left:auto;
    margin-right: auto;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.div4{
    margin-top: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div4:hover > img{
    transform: scale(1.05)
}

.strategie_img1{
    transform: scale(0.95);
    transition: all .3s
}

.div4_1{
    width: 403px;
    height: 375px;
    background: #C4C4C4;
}

.div4_2{
    width: 103px;
}

.div4_3{
    text-align: left;
    width: 534px;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.div5{
    margin-top: 82px;
    position: relative;
}

.div5_1{
    z-index:-1;
    position: absolute;
    top: 0;
    right: 0;
}

.div6{
    margin-top: 157px;
    margin-left: 134px;
    text-align: left;
    position: relative;
}

.div6_1{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
}

.div6_2{
    width: 743px;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.div6_3{
    position: absolute;
    right: 0;
    bottom: -125px;
    z-index: -1;
}

.div7{
    margin-top: 208px;
    display: flex;
    justify-content: flex-end;
}

.div7_1{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: left;
}

.div7_2{
    margin-top: 32px;
    display: flex;
}

.card{
    width: 287px;
    margin-bottom:30px;
    min-height: 171px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(242, 243, 251, 0.65);
    margin-right: 39px;
}

.card:last-child{
    margin-right: 63px;
}

.card_general{
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 16px;
    cursor: default;
}

.fa{
    transition: transform .2s;
}

.card_general:hover .fa{
    transform: scale(1.3);
}

.icon_container{
    display: table;
}

.icon{
    color: #04D98B;
    background: rgba(4, 217, 139, 0.3);
    border-radius: 50%;
    font-size: 16px;
    width: 34px;
    height: 34px;
    display: table-cell;
    vertical-align: middle;
}

.card_title{
    margin-top: 17px;
    text-align:left;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.card_text{
    margin-top: 7px;
    text-align:left;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.div8{
    margin-top: 130px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 157px;
    width: 1050px;
    background: #022873;
    border-radius: 20px;
    color: #FFFFFF;
    text-align:left;
}

.div8_general{
    padding-top: 56px;
    padding-left: 96px;
    padding-right: 40px;
    padding-bottom: 123px;
}

.div8_1{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.div8_2{
    margin-top: 24px;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.div8_3{
    margin-top: 32px;
}

@media screen and (max-width: 1100px){
    .div8{
        width: 650px;
    }

    .div7{
        margin-left: 63px;
        justify-content: flex-start;
    }

    .div7_2{
        flex-wrap: wrap;
    }

    .div6_general{
        margin-right:240px;
    }

    .div6_2{
        width: fit-content;
    }
}

@media screen and (max-width: 900px){
    .div1_general{
        padding-top: 100px;
        flex-direction: column;
        align-items: center
    }

    .div1_right{
        margin-top: 20px;
    }

    .div4_2{
        width: 0px;
    }

    .div4_3{
        padding-right: 40px;
    }

    .div2{
        margin-top: 200px;
    }

    @media screen and (max-height: 900px){
        .div1{
            min-height: 800px;
        }
    }
}

@media screen and (max-width: 750px){
    .div8{
        width: 300px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .div8_general{
        padding-left:40px;
    }

    .div7_2{
        flex-direction: column;
        align-items: center;
    }

    .div7_1{
        text-align: center
    }

    .div7{
        justify-content: center;
        margin-left: 0px;
    }

    .card{
        margin-right: 0px;
    }

    .card:last-child{
        margin-right: 0px;
    }

    .div6_3{
        width: 400px;
        bottom: -210px;
    }

    .div6{
        margin-left: 80px;
        margin-top: 50px;
    }

    .div6_general{
        margin-right: 80px;
    }
}

@media screen and (max-width: 650px){
    .div3{
        width:fit-content;
        padding-left:20px;
        padding-right: 20px;
    }

    .div2{
        padding-left:20px;
        padding-right: 20px;
    }

    .div4_3{
        text-align: center;
        margin-top: 30px;
        padding-left:20px;
        padding-right: 20px;
        width: fit-content;
    }

    .div4{
        flex-direction: column;
        margin-top: 30px;
    }

    .div1_left_1, .div1_left_2, .div1_left_3{
        width:fit-content;
        padding-left:20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 450px){
    .div1_left_1{
        margin-top: 15px;
    }

    .div1{
        min-height: 800px;
    }
}

@media screen and (max-width: 400px){
    .div1_right{
        width: 300px;
    }

    .div1_right_1{
        width: fit-content;
    }

    .strategie_img1{
        width: 300px;
    }
}
</style>